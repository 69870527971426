
@import '@/styles/global-styles.scss';
.form-template-sections-container {
  display: flex;
  flex-direction: column;
  gap: 0.5em;    
}
.add-group-button {
  margin-top: 0.5em;
  border: 1px solid $ava-border-color;
  display: flex;
  gap: 1em;
  align-items: center;
  justify-content: flex-start;
}
