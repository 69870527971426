.list-component { 

  overflow-y: scroll;

  ul {
    margin-left: 0;
  }

  li {
    margin-bottom: 0.25em;
    padding-left: 10px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    font-size: smaller;
    gap: 1em;
  }

  .list-first-column {
    font-weight: bold;
  }

}
