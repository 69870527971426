@import '../../../../javascript/src/styles/global-colors.scss';


$spinner-size: 2rem;
$spinner-border-width: 2px;
$spinner-color-start: $ava-green;
$spinner-color-end: $ava-green;
$animation-duration: 1s;

.loading-spinner {
  width: $spinner-size;
  height: $spinner-size;
  border: $spinner-border-width solid transparent;
  border-top-color: $spinner-color-start;
  border-radius: 50%;
  position: relative;
  animation: spin $animation-duration linear infinite;

  &::before,
  &::after {
    content: '';
    position: absolute;
    border: $spinner-border-width solid transparent;
    border-radius: 50%;
  }

  &::before {
    top: -$spinner-border-width;
    left: -$spinner-border-width;
    right: -$spinner-border-width;
    bottom: -$spinner-border-width;
    border-top-color: $spinner-color-end;
    animation: spin 1.5s linear infinite;
  }

  &::after {
    top: $spinner-border-width;
    left: $spinner-border-width;
    right: $spinner-border-width;
    bottom: $spinner-border-width;
    border-top-color: mix($spinner-color-start, $spinner-color-end, 50%);
    animation: spin 2s linear infinite;
  }
}

.disable-link {
  pointer-events: none;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}