
.custom-form-template-layout {
  display: flex;
  gap: 1em;
  flex-direction: column;
  .edit-preview-row {
    display: flex;
    gap: 1em;
  }
}
