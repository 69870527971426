.patient-lists-ui-complex-queries-guidelines-menu {
  height: 100%;
  .guideline-menu {
    height: 100%;
    display: flex;
    flex-direction: column;
      .guideline-menu-back-button {
        padding-top: 10px;
      }
      .guideline-menu-header {
        padding-top: 1em;
      }
      .guideline-menu-body {
        padding-top: 2em;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 3em;
        #guideline-menu-body-no-template-section {
          display: flex;
          a {
            text-wrap: nowrap
          }
        }
        .or {
          padding-top: 0.5em;
        }
        #guideline-menu-body-with-template-section {
          width: 100%;
          .more-data {
            .more-data-icon {
              padding-right: 0.5em;
            }
          }
        }
      }
      .guideline-menu-footer {
        padding: 0em 1em;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
  }
}