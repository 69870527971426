/* Use component-local class names and add them to HTML via #class_for(name) helper */

.assist-subscriber-container {
  border: 1px #212121 solid;
  border-radius: 5px;

  .loading-skeleton-container {
    padding: 0.3em 0.5em;
    display: flex;
  }

  .action-items-container {
    padding: 0em 0.5em 0.5em 0.5em;
  }

  .button-text {
    padding-top: 0.1em;
    padding-left: 0.4em;
  }

  .loading-message.italic {
    font-style: italic;
  }

  .loading-message.bold {
    font-style: bold;
  }

  .loading-icon {
    transition: color 0.3s ease-out, transform 0.3s ease-out;
  }

  .loading-message {
    transition: opacity 1s ease-out, transform 1s ease-out;
    opacity: 1;
  }

}
