.more-data-component {
  .more-data-button {
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
  }

  .more-data-icon {
    margin-right: 0.25rem;
    transition: transform 0.7s ease;
  }

  .more-data-label-bold {
    font-weight: bold;
  }

  .more-data-content {
    margin-left: 0.9em;
    display: none;
    overflow: hidden;
    transition: display 0.7s ease;
  }

  .more-data--visible {
    display: block;
  }
}
