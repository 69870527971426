.groups-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.groups-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  z-index: 1001;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.selected-groups {
  padding-left: 0.2em;
  border: 1px solid #ddd;
  border-radius: 2px;
  text-overflow: ellipsis;
}

.select-groups-button {
  padding-left: 0.2em;
}

.limit-to-groups-container {
  margin-top: -0.5em;
  margin-bottom: 0.6em;
}
