.card-ui-drawer-card {

  margin-bottom: 7px;

  .drawer-card-header {
    display: flex;
    justify-content: space-between;
    align-content: center;
    padding: 0.5em 1em;
    font-size: smaller;
    background-color: #f0f0f0 !important;
    gap: 1em;
  }

  .drawser-card-small {
    max-height: 100px;
  }

  .drawer-card-medium {
    max-height: 150px;
  }

  .drawer-card-large {
    max-height: 200px;
  }

  .drawer-card-content {
    overflow-y: scroll;
  }

  .drawer-card-title {
    font-weight: bold;
    display: inline !important;
  }

  .drawer-card-rotate-180 {
    rotate: 180deg;
  }

}