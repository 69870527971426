

.headline {
  font-size: 24px;
  text-transform: capitalize;
}

.title {
  font-size: 16px;
  text-transform: capitalize;
}

.body {
  font-size: 14px;
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  white-space: pre-wrap;
}

.caption {
  font-size: 12px;
  color: #767e8c;
}

.bold {
  font-weight: bold;
}

.capitalize {
  text-transform: capitalize;
}

.form-label {
  font-size: 0.75rem;
  font-weight: 300;
  margin: 0;
}

.warning {
  color: #931F1F;
}
.italics {
  font-style: italic;
}
