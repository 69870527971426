/* Use component-local class names and add them to HTML via #class_for(name) helper */

.ava-widget {
  .widget-title-bar {
    display: flex;
    padding: 5px 10px 4px 10px;
    margin-top: 5px;
    gap: 0.5em;
  }
    
  .widget-title-left, .widget-title-right  {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-end;
    gap: 0.35em;
  }

  .widget-title-right {
    flex: 1;
    justify-content: right;
    text-align: center;
    padding-bottom: 2px;
    gap: 0.85em;
  }

  .widget-title {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 16px;
  }

  .widget-modal-link {
    padding-bottom: 1px;
  }
}
