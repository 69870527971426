
  .new-value-rule-container {
    .new-value-rule-form-item-row {
      display: flex;
      gap: 1em;
      .operator {
        width: auto;
      }
    }
    .button-row {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 1em;
    }
  }
