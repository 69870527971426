@import '../../../assets/stylesheets/ava/colors.scss';
@import '../../../assets/stylesheets/ava/variables';

.prescribe-it-ui--prescribe-it-thread-detail-section {
  display: flex;
  flex-direction: column;
  gap: 1em;
  transition: opacity 0.3s ease-out;
  &.loading {
    opacity: 0.3;
  }

  p {
    color: grey;
  }

  .message-detail-card-display-item {
    display: flex;
    flex: 1;
    gap: 0.5em;
    min-width: 50%;
  }
  
  .message-detail-card-display-item-container {
    display: flex;
    flex-wrap: wrap;
  }
  .listed-messages-section {
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 1em;
    .message-detail-card-display-item {
      color: gray;
      white-space: pre-line;
    }

    .e-prescription-card {
      border-radius: $ava-border-radius;
      outline: 1px solid $ava-secondary-dark-color;
      min-width: 400px;
      width: 75%;
      align-self: flex-end;
      .erx-card-header {
        background-color: $ava-secondary-color;
      }
      .e-prescription-card-section {
        padding: 1em;
        &:not(:last-child) {
          border-bottom: 1px solid $ava-secondary-dark-color;
        }
      }
    }
    .cancellation-card {
      align-self: flex-end;
      padding: 1em;
      border-radius: $ava-border-radius;
      outline: 1px solid $ava-secondary-dark-color;
      background-color: $ava-secondary-color;
      min-width: 400px;
      width: 75%;
      float: right;
    }
    .prescribe-it-thread-message {
      padding: 1em;
      border-radius: $ava-border-radius;
      outline: 1px solid $ava-primary-dark-color;
      background-color: $ava-primary-color;
      min-width: 400px;
      width: 75%;
      align-self: flex-start;
      &.prescribe-it-thread-message-error {
        align-self: flex-end;
        background-color: $ava-secondary-color;
        outline: 1px solid $ava-secondary-dark-color;
        strong {
          color: $ava-secondary-darkest-color;
        }
      }
    }
  }
}