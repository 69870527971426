/* Use component-local class names and add them to HTML via #class_for(name) helper */

.medications-ui-prescribe-it-messages-page {
  display: flex;
  gap: 2em;
  flex-wrap: nowrap;
  .message-list {
    min-width: 300px;
    width: 25%;
    max-width: 400px;
  }
  .message-details-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1em;

    .message-detail-card {
      width: 100%;
      outline: 1px solid black;
      min-height: 100px;
    }

    .message-form {
      min-height: 100px;
      outline: 1px solid black;
      width: 100%;
    }
  }
  .message-body {
    outline: 1px solid black;
  }
}

// TODO: Can delete when we move to christopher's modal
#full_medication_modal {

  // following allows for medications-ui-prescribe-it-messages-page to scroll
  #medications-modal-body {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 0px;

    .medications-ui-prescribe-it-messages-page {
      flex: 1;
    }
  }
}