#decipher-tray {
  position: fixed;
  top: 4em;
  bottom: 0;
  right: 0;
  width: 27%;
  z-index: 20;
  background: white;
  border: 1px solid grey;
  transition: right 0.6s ease;
}

#decipher-tray.closed {
  display: none;
}
