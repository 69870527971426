/* Use component-local class names and add them to HTML via #class_for(name) helper */

.application-desktop-menu-bar {
  .menu {
    li {
      border: none;
    }
  }
}

.application-desktop-menu-bar.menu > {
  li {
    border-left: 1px solid #4e4e4e;
    &:first-child {
      border-left: none;
    }
  }
}
