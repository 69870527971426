
.edit-form-template-title-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 1em;
  .edit-form-template-title {
    flex-grow: 1;
  }
}
