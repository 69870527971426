

/* Main container styles */
.bills-ui--bill-advisor--ab--time-allocation-container {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  max-width: 400px;
  margin: 0 auto;

  .time-section {
    flex: 1;
  }
  
  .time-display {
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: 600;
    color: #333;
  }
  
  .time-display i {
    margin-left: 0.5rem;
    color: #666;
  }
  
  .date-section .input-group {
    width: 160px;
    margin-bottom: 0;
  }
  
  /* Section styles */
  .section {
    margin: 1rem 0;
  }
  
  .section-header {
    font-size: 1rem;
    font-weight: 600;
    color: #333;
    margin-bottom: 0.25rem;
  }
  
  .section-divider {
    height: 1px;
    background-color: #e0e0e0;
    margin: 1rem 0;
  }
  
  /* Time row styles */
  .time-row {
    display: flex;
    align-items: center;
    margin-bottom: 0.75rem;
  }
  
  .time-label {
    flex: 2;
    padding-left: 1.25rem;
    font-size: 0.9rem;
    color: #555;
  }
  
  .time-value-link {
    flex: 1;
    text-decoration: none;
    color: #333;
    transition: all 0.2s ease;
  }
  
  .time-value {
    text-align: center;
    font-size: 0.9rem;
    padding: 0.35rem;
    background-color: #f8f8f8;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    transition: all 0.2s ease;
  }
  
  .time-value-link:hover .time-value {
    background-color: #f0f0f0;
    border-color: #ccc;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  }
  
  .time-indicator {
    flex: 1;
    margin-left: 1rem;
  }
  
  /* Total row styling */
  .total-row {
    margin-top: 1rem;
  }
  
  .total-row .time-label {
    font-weight: 600;
  }
  
  .total-row .time-value {
    font-weight: 600;
    background-color: #f0f0f0;
  }
  
  /* Ensure the total allocated and balance rows don't look clickable */
  .total-row .time-value,
  .balance-row .time-value {
    margin: 0;
  }
  
  /* Balance row styling */
  .balance-row {
    margin-top: 0.5rem;
  }
  
  .balance-row .time-label {
    font-weight: 600;
  }
  
  .balance-row .time-value {
    font-weight: 600;
  }
  
  /* Progress bar */
  .progress-bar {
    height: 1.5rem;
    background-color: #4CAF50;
    border-radius: 1rem;
    color: white;
    font-size: 0.75rem;
    line-height: 1.5rem;
    text-align: center;
    transition: width 0.3s ease;
  }
  
  /* Balance indicator */
  .balance-indicator {
    width: 1.75rem;
    height: 1.75rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
  }
  
  /* Color classes */
  .green-icon {
    color: #4CAF50;
  }
  
  .red-icon {
    color: #f44336;
  }
  
  .green-icon.balance-indicator {
    background-color: #4CAF50;
    color: white;
  }
  
  .red-icon.balance-indicator {
    background-color: #f44336;
    color: white;
  }
  
  /* Action buttons */
  .action-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;
  }
  
  .cancel-button, .save-button {
    flex: 1;
    padding: 0.75rem;
    border-radius: 4px;
    font-size: 0.9rem;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .cancel-button {
    background-color: #f5f5f5;
    color: #555;
    margin-right: 0.5rem;
    border: 1px solid #ddd;
  }
  
  .save-button {
    background-color: #4CAF50;
    color: white;
    margin-left: 0.5rem;
  }
  
  .cancel-button:hover {
    background-color: #e8e8e8;
  }
  
  .save-button:hover {
    background-color: #45a049;
  }
}
