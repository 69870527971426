@import "../../../assets/stylesheets/ava/colors.scss";
@import '../../../javascript/src/styles/global-colors.scss';


.ava-rich-textbox {
  border: 1px solid $ava-border-color;
  .rich-textbox-dynamic-macro-node {
    outline: 1px dotted; 
    border-radius: 4px; 
    padding: 2px 8px;
    background-color: $ava-accent-color;
  }
  h1 {
    font-size: 1.6rem;
  }
  h2 {
    font-size: 1.2rem;
  }
  a{
    border-bottom: 2px solid black;
  }
  ul, ol{
    padding-left: 1rem;
    margin-bottom: 0.5rem;
    list-style-position: outside;
  }
  ul{
    list-style-type: disc;
  }
  ol{
    list-style-type: decimal;
  }

  // link
  &__link-area{    
    padding: 0.2rem;
    transition: all 0.2s;
    opacity: 1;
    display: flex;
    gap: 3px;
  }
  &__link-area--hidden{
    display: none;
    
  }
  &__link-field::placeholder{
    font-style: italic;
    color: hsl(0, 0%, 45%);
  }
  &__link-field{
    border: 1px solid hsl(0, 0%, 70%); 
    border-radius: 4px;
    padding: 0.2rem 0.6rem;
    width: 100%;
    font-size: 0.9rem;
  }
  &__link-field:focus::placeholder {
    color: hsla(0, 0%, 0%, 0);
  }
  &__link-button{
    background-color:  hsl(0, 0%, 91.8%) !important;
    padding: 0.2rem 0.5rem;
    border-radius: 5px;
  }
  &__link-button:hover{
    background-color: hsl(115.6, 73.2%, 78%) !important;
    transition: all 0.2s;
  }

  &__container{
    display: flex;
    flex-direction: column;
  }

  &__content-editable{
    min-height: 100px;
  }

  &__toolbar{
    display: flex;
    gap: 1em;
    white-space: nowrap;
    background-color: hsl(0deg, 0%, 92%);
    padding: 0.5em 1em;
    justify-content: flex-start;
  }

  &__button-row{
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    
  }

  &__button-group { 
    display: flex;
    width: min-content;
    gap: 0.5em;
  }

  &__button {
    height: 2rem;
    padding: 0 0.7em;
    opacity: 0.6;
    border-radius: 5px;
      &.font-button {
        display: none;
      }
  }

  &__button:hover {
    opacity: 1;
  }

  &__button--selected{
    background-color: hsl(249, 31.6%, 80%) !important;
    opacity: 1 !important;
    &.font-button {
      display: block
    }
  }
  

  .ProseMirror {
    white-space: pre-wrap;
    padding: 1em;
    min-height: inherit;
  
    em {
      font-style: italic;
      line-height: inherit;
      font-weight: inherit;
    }
  }
}
