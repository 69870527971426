#rooms-index .room-widget {
  position: relative;
  padding: 0.5em;
  width: 12em;
  border: 1px grey solid;
  border-radius: 5px;
  background: white;
}

.room-widget .roomable-appointments-select-box {
  border: 1px solid #ccc;
}

.room-widget .appointment-search {
  position: absolute;
  width: 23.5em;
  border: 1px grey solid;
  background: white;
  height: 12em;
  top: -6em;
  left: 12em;
  z-index: 100;
  padding: 0.25em;
  border-radius: 5px;
  overflow-y: auto;
  overflow-x: hidden;
}

.room-widget-container .appointment-search-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.35);
  z-index: 99;
  display: none;
}

.room-widget .appointment-search-field {
  position: sticky;
}

.room-widget.active {
  z-index: 100;
}

.room-widget .appointment-search .roomable-appointment {
  margin: 0em;
  position: relative;
}
