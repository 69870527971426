
@import '@/styles/global-styles.scss';
.base-input-container {
  width: inherit;
  .base-input {
    text-align: inherit;
    padding: 4px;
    border: 1px solid $ava-border-color;
    height: $ava-form-item-height;
    line-height: $ava-form-item-line-height;
    padding: $ava-form-item-padding;
    margin: 0px;
    width: 100%;
  }
}
