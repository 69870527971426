/* Use component-local class names and add them to HTML via #class_for(name) helper */

.account-management-page {
  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: center;
  justify-content: center;
  max-width: 400px;
  margin: auto;
}