@import '../../../assets/stylesheets/ava/variables.scss';


.prescribe-it-ui--prescribe-it-thread-list {
  &:not(:last-child) {
    border-bottom: $ava-border;
  }
  .medication-message-title {
    text-align: right;
  }
  .list-header {
    display: flex;
    gap: 1em;
    align-items: center;
    padding: 0.5em;
    select, input {
      margin-bottom: 0;
    }
    .filter-dropdown {
      width: auto;
    }
  }
}
