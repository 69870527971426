@import '../../../javascript/src/styles/global-colors.scss';


.ava-button-shell-component {
  background-color: transparent;
  color: inherit;
  display: inline-flex;

  &:not(.icon)>* {
    font-size: 0.76rem;
  }

  > * {
    line-height: inherit;
    padding: 0.5em 1em;
    border-radius: 7px;
    &[disabled] {
      opacity: 0.25;
      cursor: not-allowed;
    }
  }

  > form { // button_to formatting:
    padding: 0em;
    input[type="submit" i], button {
      padding: 0.5em 1em;
      background-color: transparent;
      border-style: initial;
    }
    button {
      min-height: 30px;
    }
  }
  

  &.outline {
    > * {
      border: 1px solid $ava-border-color;
    }
  }
  &.primary {
    > * {
      border: 1px solid $ava-affirmative-color;
      color: $ava-affirmative-color !important; 
    }
  }
  &.danger {
    > * {
      border: 1px solid $ava-danger-color;
      color: $ava-danger-color !important;
    }
  }
  &.warning {
    > * {
      border: 1px solid $ava-warning-color;
      color: $ava-warning-color !important;
    }
  }
  &.icon {
    width: 1.7em;
    height: 1.7em;
    border: none;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
      transition: opacity 0.15s ease-in;
      background: rgba(0, 0, 0, 0.1);
      border-radius: 5px;
    }
    > * {
      position: absolute;
      inset: 0; // This is shorthand for top: 0; right: 0; bottom: 0; left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      padding: 0em;
    }
    a:hover {
      background-color: transparent !important; // unfortunately important is needed because of an older important rule in application.scss
    }
  }

  &.menu-item {
    display: flex;
    > * {
      flex-grow: 1;
      outline: 1px solid grey;
      background-color: #FFFFFF;
      border-radius: 10px;
    }
  }
  &:hover {
    text-shadow: 0px 0px black;
  }

  &.confirm {
    > * {
      border: 1px solid $ava-affirmative-color;
      color: white;
      background-color: $ava-affirmative-color !important;
      
      &:hover {
        background-color: #287261 !important;
      }
    }

  }

  &.shade {
    border-radius: 0.75em;
  
    background-color: $ava-background-color;

    &:hover {
      background-color: $ava-tertiary-background-color;
    }
  }

  &.simple {
    color: inherit;
  }

}