.consults-ui-consult-header-title {
  display: flex;
  gap: 1em;
  align-items: center;
  
  h6 {
    margin-bottom: 0px;
  }
}

.consult-ui-edit-consult-view-bottom-button-row {
  display: flex;
  gap: 1em;
  justify-content: space-between;
  align-items: center;
}


.consults-ui-tasks-container {
  .consult-ui-consult-task-link {

    a {
        border-radius: 5px;
        display: flex;
        gap: 1em;
        align-items: center;
        padding: 2px;
     }
  }

  span {
    font-weight: bold;
  }
}