.get-action-items-link {

  border: 1px #212121 solid;
  border-radius: 5px;
  display: flex;
  padding: 0.2em 0.5em;

  .button-text {
    padding-left: 0.4em;
    padding-top: 0.25em;
    font-style: bold;
  }

}
