.patient-view-ui-electronic-prescriptions {
  margin: auto;
  width: 50%;
  min-height: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}