@import '../../../javascript/src/styles/global-colors.scss';
/* Use component-local class names and add them to HTML via #class_for(name) helper */

$use-important: true;

.medications-ui-section-subheader {
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding: 0.5em 0em;

  .left-aligned-row {
    display: flex;
    align-items: center;
    align-content: flex-start;
    padding-left: 0.5em;
    a {
      padding: 0.5em;
      border: 1px solid $ava-border-color;
      background-color: $ava-button-unselected-background;
      pointer-events: none;
      color: gray if($use-important, !important, null);
      &:first-child {
        border-radius: 0.5em 0 0 0.5em;
      }
      &:last-child {
        border-radius: 0 0.5em 0.5em 0;
        border-left: none;
      }
      &.selected {
        border: 1px solid $ava-text-color;
        background-color: transparent;
        color: $ava-text-color if($use-important, !important, null);
      }
      &:not(.selected) {
        pointer-events: all;
        &:hover {
          background-color: transparent if($use-important, !important, null);
          color: $ava-text-color if($use-important, !important, null);
        }
      }
    }
  }
  .right-aligned-row {
    display: flex;
    align-items: center;
    align-content: flex-end;
    gap: 1em;
  }
}