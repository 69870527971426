.clinical-notes-ui-show-card {
  .clinical-notes-ui-show-card-header {
    .clinical-notes-ui-show-card-author-buttons-row {
      display: flex;
      gap: 1em;
      justify-content: space-between;
      .title {
        flex-grow: 1;
      }
      .menu-button-row {
        display: flex;
        gap: 1em;
      }
    }
  }
}