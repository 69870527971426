/* Use component-local class names and add them to HTML via #class_for(name) helper */

.ava-add-to-existing-flowsheet-component {


  .archived-button,
  .ongoing-button {
    padding: 5px;
    border: 1px solid
  }

  .ongoing-button {
    border-radius: 5px 0px 0px 5px;
    border-right: none;
  }
  .ongoing-button.active {
    border-right: 1px solid;
  }

  .archived-button {
    border-radius: 0px 5px 5px 0px;
    border-left: none;
  }

  .archived-button.active {
    border-left: 1px solid;
  }

  .active {
    opacity: 1;
  }
  
  .inactive {
    opacity: 0.5;
  }
  
  .header {
    margin-bottom: 0.5rem;
  }
  
  .prior-flowsheets-container {
    height: 10.5em;
    overflow: scroll;
  }
  
  ::-webkit-scrollbar {
    display: none; /* Hide scrollbar on Chrome and Safari */
  }
  
  .hidden-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

}  
