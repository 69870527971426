
.form-options-manager {
  .form-options-manager-list-item,
  .form-options-manager-new-value-container {
    padding-left: 2em;
  }
  .form-options-manager-list-item {
    display: flex;
    align-items: center;
    gap: 1em;
  }
  .form-options-manager-new-value-container {
    display: flex;
    gap: 1em;
  }
}
