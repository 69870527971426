/* Use component-local class names and add them to HTML via #class_for(name) helper */


.ava-connect-ui-messages-ui-patient-message {
	width: 100%;
	min-width: 100%;
  .message-content-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1em;
    margin-bottom: 1em;
    .message-content-box {
      white-space: break-spaces;
    }
  }
}