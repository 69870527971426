@import '../../../javascript/src/styles/global-colors.scss';

.migration-attempt-component-container {
  border: 2px solid $ava-border-color;
  margin-top: 1em;

  .migration-attempt-status {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1.25rem;
    padding: 0.5rem;
  }

  .migration-attempt-header, .migration-attempt-section {
    display: flex;
    gap: 2em;
    padding: 0.5em;
    border-bottom: 1px solid $ava-border-color;
  }

  .migration-attempt-section {
    .sub-section {
      display: flex;
      gap: 1em;
      padding: 0;
      border-bottom: 0;
    }

    &:last-child {
      border-bottom: 0;
    }
  }

}