.custom-context-menu {
	position: fixed;
	width: 140px;
	box-shadow: 0 4px 5px 3px rgba(0, 0, 0, 0.2);
	display: none;
	background-color: white;

	.custom-context-menu-chevron {
		position: absolute;
		right: 3px;
		top: 5px;
	}

	.custom-context-menu-submenu {
		padding: 3px 5px;
		position: relative;
		cursor: pointer;
		width: 100%;

		&:hover {
			background: rgba(0, 0, 0, 0.1);
			.custom-context-menu-options {
				display: block;
				left: 120px;
			}
		}

		.custom-context-menu-options {
			list-style: none;
			display: none;
			position: absolute;
			box-shadow: 0 4px 5px 0px rgba(0, 0, 0, 0.2);
			width: 140px;
			background-color: white;

			li {
				padding: 3px 5px;
				&:hover {
					background: rgba(0, 0, 0, 0.1);
					cursor: pointer;
				}
			}

			.li-seperator {
				padding: 0px;
				border-bottom: 1px solid #DCDCDC;
			}
		}
	}
}