
@import '@/styles/global-styles.scss';

.ava-textbox-grow {
  border: 1px solid $ava-border-color;
  padding: $ava-form-item-padding;
  box-sizing: border-box;
  width: 100%;
  padding: 1em;
  &:focus {
    box-shadow: 0px 0px 3px rgb(80, 143, 155);
  }
}

.ava-textbox-grow.disabled-class {
  border: 1.5px solid rgba(0, 0, 0, 0.2);
  background: rgba(242, 244, 245, 255);
}
