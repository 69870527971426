
.conditional-rule-manager {
  .rules-list-container {
    display: flex;
    gap: 1em;
    flex-wrap: nowrap;
    margin: 2em 0em;
    padding-left: 2em;
  }
  .list-item {
    display: flex;
    align-items: center;
    gap: 2em;
  }
}
