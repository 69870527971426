/* Use component-local class names and add them to HTML via #class_for(name) helper */

.bills-form-location-form-item-layout {
  display: flex;
  position: relative;
  &.warning {
    outline: 1px solid;
    .warning-statement {
      display: block;
      position: absolute;
      bottom: -12px;
      left: 10px;
      background-color: white;
    }
  }
  .warning-statement {
    display: none;
  }
}

