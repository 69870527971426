
.base-button-icon {
  background-color: inherit;
  width: 24px;
  height: 24px;
  border: none;
  border-radius: 5px;
  padding: 4px;
  cursor: pointer;
  &:hover,
  &:focus {
    transition: opacity 0.15s ease-in;
    background: rgba(0, 0, 0, 0.1);
  }
  &:active {
    transform: scale(0.98);
    transition: opacity 0.15s ease-out;
    background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(136, 98, 98, 0.2));
  }
}
