
@import '@/styles/global-styles.scss';
.base-drop-down-menu {
  height: 100%;
  .drop-down-button-list-background {
    position: fixed;
    z-index: 2;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
  }
  .drop-down-container {
    position: relative;
    height: 100%;
    .menu {
      box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
      min-width: 100px;
      z-index: 2;
      position: absolute;
      background-color: $ava-background-color;
      @media (max-width: $ava-breakpoint-sm) {
        max-width: 100vw;
      }
      button {
        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }
}
