$ava-background-color: #f6f6f6;
$ava-primary-background-color: #e2e1f1ff;
$ava-secondary-background-color: #f3cedcff;
$ava-tertiary-background-color: #e9e8eaff;

$ava-text-color: #181624;
$ava-border-color: #cacaca;
$ava-button-unselected-background: #e8e8e8;

$ava-affirmative-color: #2eb496ff;
$ava-danger-color: #e22e6eff;
$ava-warning-color: #FF7F50;

$ava-green: #2eb496ff;
$ava-red: #e22e6eff;
$ava-purple: #675cd3;
$ava-dark-blue: #181624;
$ava-blue: #3030d3;