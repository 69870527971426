#rooms-container {
  position: absolute;
  left: 1em;
  bottom: 1em;
  z-index: 5;
  background: rgba(255, 255, 255, 0.95);
  width: 72%;
  border: 1px grey solid;
  border-radius: 5px;
  padding: 0.5em;
}

#rooms-container-toggle {
  cursor: pointer;
}
