::-webkit-scrollbar {
  opacity: 0;
  width: 8px;
  height: 8px;
}

*:hover::-webkit-scrollbar {
  opacity: 1;
}

*:hover::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 6px; 
}

*:hover::-webkit-scrollbar-track {
  background: #f1f1f1;
}
