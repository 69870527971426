.toggle-button-component {
  display: flex;

  .toggle-button-left {
    border-radius: 5px 0px 0px 5px;
  }

  .toggle-button-right {
    border-radius: 0px 5px 5px 0px;
  }

  .active-toggle-black {
    background-color: white;
    outline: 1px solid;
    padding: 5px;
    margin: 1px;
  }

  .inactive-toggle-black {
    background-color: #f0f0f0;
    padding: 5px;
    color: grey !important;
  }

  .inactive-toggle-black-dark {
    @extend .inactive-toggle-black;
    background-color: #C8C7CB;
  }
}