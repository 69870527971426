
.edit-patient-form-template {
  display: flex;
  flex-direction: column;
  gap: 2em;
  .edit-form-template-title-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 1em;
  }
  .form-template-questions-container {
    display: flex;
    flex-direction: column;
    gap: 0.5em;    
  }
}
