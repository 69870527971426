/* Use component-local class names and add them to HTML via #class_for(name) helper */

.slide-in-popup-component {

  .footer-slide-in-popup-container {
    pointer-events: auto;
    flex: 0 1 auto;
    position: relative;
    z-index: 2000;
  }

  .slide-in-popup {
    background-color: white;
    box-shadow: 0 5px 8px 0 hsla(0, 0%, 0%, 0.3);
    pointer-events: auto;
    border-radius: 1em;
    border: 1px solid #AAA;
    padding: 1em;
    z-index: 2000;
    overflow-y: auto;
  }

  .slide-in-popup-fixed-right {
    position: fixed;
    right: 5px;
    top: 5px;
    bottom: 4.5em;
    width: 25%;
  }

  .slide-in-popup-hidden {
    display: none;
  }

  .max-z-index {
    z-index: 999999999999999999;
  }
  
  .footer-container-right-large {
    box-shadow: 0 10px 15px 0 hsla(0, 0%, 0%, 0.5);
    position: absolute;
    right: 0em;
    bottom: 0.5em;
    height: 85vh; // default height
    width: 30vw; // default width
    overflow-y: auto;
  }
}

// For preview.rb/preview.html.erb
.slide-in-popup-preview-container {
  position: absolute;
  bottom: 20px;
  right: 50%;
}