
  .create-patient-form-template-container {
    margin: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2em;
    .title-row {
      display: flex;
      gap: 1em;
      align-items: center;
      .ava-form-title {
        text-transform: none;
      }
    }
    .start-template-title-form {
      display: flex;
      flex-direction: column;
      gap: 1em;
      .create-form-button {
        max-width: 200px;
      }
    }
  }
