.card-ui-foldable-card {

  margin-bottom: 1.2em;

  .foldable-card-header {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding: 0.5em 1em;
    font-size: 1em;
    background-color: #f0f0f0 !important;
    gap: 1em;

    .foldable-subtitle {
      padding: 0.25em;
      margin-left: 0.5em;
    }

    .end-items {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      align-items: center;
      gap: 1em;
    }
  }

  .foldable-card-content {
    max-height: fit-content;
    border: 2px solid #f0f0f0;
    box-shadow: 0.5px 5px 0.5px -0.5px #f0f0f0;
    padding: 10px;
  }

  .foldable-card-chevron {
    cursor: pointer;
    margin-right: 0.25em;

    &.primary-foldable-card {
      font-size: 1.5em;
    }
  }

  .foldable-card-title {
    font-weight: bold;
    display: inline !important;
    cursor: pointer;

    &.primary-foldable-card {
      font-size: 1.5em;
    }
  }

  .foldable-card-rotate-180 {
    rotate: 180deg;
  }

}