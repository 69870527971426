.card-header-component {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em 1em;
  
  .header-title {
    margin: 0;
  }

  .card-header-component-button-row {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}