$pdftron-webviewer-form-sizes: (
  small: 500px,
  medium: 700px,
  large: 1000px
);

.pdftron-webviewer-form {
  @each $size, $height in $pdftron-webviewer-form-sizes {
    &.#{$size} {
      height: $height;
    }
  }
}

.decipher-panel {
  position: absolute;
  top: 0;
  right: -400px;
  width: 400px;
  height: 100%;
  background-color: white;
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.1);
  transition: right 0.3s ease;
  z-index: 1000;
}

.decipher-panel--visible {
  right: 0;
}
