
@import '@/styles/global-styles.scss';
.form-editor-card-header {
  cursor: pointer;
  border-radius: 0.2em;
  &:hover {
    box-shadow: 0 0 0 1px $ava-affirmative-color;
  }
  padding: 1em;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .title-row,
  .icon-row {
    display: flex;
    align-items: center;
    gap: 1em;
    
  }  
}
