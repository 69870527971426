
  @import '@/styles/global-styles.scss';
.patient-forms-template-list {
  display: flex;
  flex-direction: column;
  gap: 1em;
  .edit-template-button {
    width: 100%;
    height: 2em;
    border: 1px solid $ava-border-color;
    &:hover {
      border: 1px solid $ava-affirmative-color;
    }
  }
  .patient-form-selection {
    cursor: pointer;
    .published-icon {
      color: #51AF98
    }
    .not-published-icon {
      color: #cc5500
    }    
  }
}
