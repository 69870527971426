
@import '@/styles/global-styles.scss';

.base-label {
  width: inherit;
  text-transform: capitalize;
  .form-item-container {
    display: flex;
    flex-grow: 1;
    * {
      flex-grow: 1;
    }
  }
  hr {
    margin-bottom: 0.5em;
    margin-top: 0em;
  }
  &.inline {
    display: flex;
    white-space: nowrap;
    background-color: $ava-background-color;
    border: 1px solid $ava-border-color;
    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      height: inherit;
      box-sizing: border-box;
      border-right: 1px solid $ava-border-color;
      padding: 0 1em;
    }
    input {
      border: 1px solid transparent;
    }
  }
}
