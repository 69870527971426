#turbo-toast {
  position: fixed;
  bottom: 5em;
  right: 0;
  left: 0;
  margin-right: auto;
  margin-left: auto;
  min-height: 2em;
  width: 35%;
  z-index: 99999999999;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
  font-size: 110%;
  text-decoration-color: #000;
  padding: 0.5em;
  text-align: left;
  border-radius: 0.5em;
}

#turbo-toast.fade-in {
  opacity: 1;
  visibility: visible;
}

#turbo-toast.fade-out {
  opacity: 0;
  visibility: hidden;
}

#turbo-toast.success {
  background: #c6f6d5;
  border-left: 0.5em solid #48bb78;
}

#turbo-toast.error {
  background: #fbd38d;
  border-left: 0.5em solid #dd6b20;
}

#turbo-toast .toast-close-button {
  position: absolute;
  top: 0.1em;
  right: 0.4em;
  font-size: 1.3em;
  cursor: pointer;
}

#turbo-toast ul li {
  font-size: 120%;
}
