$too-short-color: #817d7c;
$weak-color: #c74e3d;
$mediocre-color: #d68870;
$good-color: #83c0cc;
$strong-color: #508f9b;

@mixin level($width, $color) {
  width: $width;
  background-color: $color;
  border: 0.1em;
}

.password-strength-bar-container {
  
  .strength-bar {
    height: 0.8em;
    display: block;
    border-radius: 0.5em;
    transition: all 0.5s linear;
  
    &.level-0 {
      @include level(10%, $too-short-color);
    }
    &.level-1 {
      @include level(25%, $weak-color);
    }
    &.level-2 {
      @include level(50%, $mediocre-color);
    }
    &.level-3 {
      @include level(75%, $good-color);
    }
    &.level-4 {
      @include level(100%, $strong-color);
    }
  }
  
  .strength-label {
    &.level-0 {
      color: $too-short-color;
    }
    &.level-1 {
      color: $weak-color;
    }
    &.level-2 {
      color: $mediocre-color;
    }
    &.level-3 {
      color: $good-color;
    }
    &.level-4 {
      color: $strong-color;
    }
  }
}

