
@import '@/styles/global-styles.scss';
.form-editor-card-container {
  border: 1px solid $ava-border-color;
  border-radius: 0.2em;
  background-color: white;
  .form-editor-card-body {
    padding: 1em;
  }
}
