/* Use component-local class names and add them to HTML via #class_for(name) helper */

.ava-tooltip {
  border: 3px solid black;
  max-width: 400px;
  padding: 0.5em 1em;
  position: fixed;
  z-index: 1000000 !important;
  background-color: white;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
}

.ava-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
}
