
@import '@/styles/global-styles.scss';

.form-editor-saving-message-text-container {
  position: fixed;
  bottom: 1em;
  .form-editor-saving-message-text-pill {
    padding: 0.5em 0.7em;
    border-radius: 8px;
    border: 1px solid $ava-border-color;
    background-color: $ava-background-color;
  }
}
