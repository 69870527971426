.shared-ui--hover-menu {
  position: relative;
  // padding-left: 0.25em;
  // padding-right: 0.25em;

  /* Base z-index for the container */
  z-index: 1;

  /* When hovering, increase z-index to ensure entire menu gets priority */
  &:hover {
    z-index: 10;

    /* Ensure the chevron icon stays below the menu items when hovering */
    .fa-chevron-down {
      z-index: 1;
    }
  }

  /* Create a pseudo-element that extends the hover area */
  &::before {
    content: '';
    position: absolute;
    top: -15px;
    left: -8px; /* this hover area solution does factor in "width" of items leading to scroll bars and it can cover neighbouring buttons */
    right: -8px; /* trimming to -8px for now until we can think of an alternative solution */
    bottom: -15px;
    z-index: -1;
  }

  /* Style for the chevron icon */
  .fa-chevron-down {
    position: relative;
    z-index: 2;
  }

  .menu-items {
    display: none;
    flex-direction: column;
    gap: 0.5em;
    position: absolute;
    width: 15em;
    background-color: #fff;
    border: 1px solid black;
    border-radius: 3px;
    padding: 5px;
    text-align: left;
    
    /* Ensure dropdown menu appears above everything when visible */
    z-index: 20;

    /* Add buffer space around the dropdown */
    &::before {
      content: '';
      position: absolute;
      top: -10px;
      left: -10px;
      right: -10px;
      bottom: -10px;
      z-index: -1;
    }

    &.open-right {
      right: 0;
    }

    &.open-left {
      left: 0;
    }

    &:hover {
      display: flex;
    }
  }

  .menu-item {
    position: relative;
    width: 100%;
    padding: 0px;
    text-align: left;

    a {
      display: block;
      width: 100%;
      padding: 0.4em;
      text-decoration: none;

      &:hover {
        background-color: #f5f5f5;
      }
    }
  }
}