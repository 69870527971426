
@import '@/styles/global-styles.scss';
.edit-group-container {
    background-color: $ava-background-color;
  .edit-group-card-header-row {
    padding: 1em;
    background-color: white;
    display: flex;
    justify-content: space-between;
    &.active {
      background-color: $ava-background-color;
    }
  }
  .edit-group-body {
    background-color: $ava-background-color;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
  }
  .edit-form-item-list-container {
    display: flex;
    flex-direction: column;
    gap: 0.5em;    
  }
}
