
@import '@/styles/global-styles.scss';
.form-editor-input-container {
  .input-row-container {
    display: flex;
    align-items: center;
    gap: 1em;
    width: 100%;
    .form-template-editor-input {
      width: inherit;
      background-color: transparent;
      border: none;
      border-bottom: 1px solid $ava-border-color;
    }
  }
}
