.ava-patient-notice-item-component {
  .patient-notice-message-and-note {
    flex-grow: 1;
  }
  a {
    display: block;
    padding: 4px;
  }
  &:not(:last-child) {
    padding-bottom: 1em;
  }
}

// used in: app/views/patient_view/patient_notices/_popup.html.erb
.patient-notice-popup { 
  &:not(:last-child) {
    border-bottom: 1px solid;
  }
}