@import 'variables';

$breakpoint-small: 33.75em; // 540px
$breakpoint-med: 45em; // 720px
$breakpoint-large: 60em; // 960px

$height-breakpoint-small: 41.7em; // 667px
$height-breakpoint-med: 48.9em; // 783px
$height-breakpoint-large: 56.3em; // 900px

$width: 100%;
$grid-columns-single: repeat(auto-fill, minmax(0, 100%));
$grid-columns-double: repeat(auto-fill, minmax(0, 50%));

.ava-hidden-sm {
  display: none;
}

.ava-col-auto {
  width: auto;
}

@for $i from 1 through 12 {
  .ava-col-#{$i} {
    width: $width;
  }
}

@for $i from 1 through 12 {
  .ava-col-#{$i}-sm {
    width: calc((100% / 12 * #{$i}) - #{$ava-spacing-unit});
  }
}

@media only screen and (min-width: $breakpoint-med) {
  @for $i from 1 through 12 {
    .ava-col-#{$i} {
      width: calc((100% / 12 * #{$i}) - #{$ava-spacing-unit});
    }
  }

  .ava-hidden-sm {
    display: block;
  }
}

.ava-grid {
  display: grid;
}

.ava-grid-single-column {
  grid-template-columns: $grid-columns-single;
}

.ava-grid-double-columns {
  grid-template-columns: $grid-columns-double;
}