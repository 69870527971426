.patient-demographics-ui-ava-identifier-group {
  display: flex;

  > input[type="text"] {
    border: 1px solid #ccc; 
    border-right: 0;
    border-radius: 7px 0 0 7px; 
    background-color: white; 
  }

  > input[type="submit"] {
    border: 1px solid #ccc; 
    border-left: 0;
    border-radius: 0 7px 7px 0; 
    background-color: #51AF98; 
    color: white;  
  }
}