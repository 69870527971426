.sso-button {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 6px;
  border: 1px solid black;
  background-color: #FFF;
  color: #000;
  font-weight: 500;
  transition: background-color 0.3s ease;
  width: 100%;
  cursor: pointer; // Added to give the button a pointer cursor like links
  
  &:hover {
    background-color: darken(#EEE, 10%);
  }
  
  &__icon {
    height: 24px;
    width: 24px;
    margin-right: 8px;
  }
  
  &__text {
    font-size: 16px;
    flex-grow: 1;
    text-align: center;
  }
}

// Reset form default styles
form {
  margin: 0;
  padding: 0;
  width: 100%;
}