.loading-logo-component {
  pointer-events: none;
  margin: 0.2em 0em 0em 0em;

  .logo-small {
    width: 10px;
    height: 10px;
  }

  .logo-medium {
    width: 20px;
    height: 20px;
  }

  .logo-large {
    width: 32px;
    height: 32px;
  }

  .loading-logo {
    display: inline-block;
    position: relative;
    line-height: 0; /* Prevents any extra spacing */
  }
  
  .loading-logo svg {
    display: block; /* Prevents any extra spacing */
    max-width: 100%;
    max-height: 100%;
  }
  
  .loading-logo__petal {
    opacity: 0;
    animation: fade-sequence 2s infinite;
    fill: #9182ee; /* Base color, you can adjust this */
  }
  
  .loading-logo__petal--top {
    animation-delay: 0s;
  }
  
  .loading-logo__petal--right {
    animation-delay: 0.5s;
  }
  
  .loading-logo__petal--bottom {
    animation-delay: 1s;
  }
  
  .loading-logo__petal--left {
    animation-delay: 1.5s;
  }
  
  @keyframes fade-sequence {
    0%, 100% {
      opacity: 0.3;
    }
    25% {
      opacity: 1;
    }
    50% {
      opacity: 0.3;
    }
  }

  .loading-logo--completed {
    .loading-logo__petal {
      animation: none;
      opacity: 1;
      transition: opacity 0.3s ease-out;
    }
  }

  .loading-logo__petal {
    .loading-logo--completed & {
      animation: none;
      opacity: 1;
    }
  }

}
