@import '../../../../assets/stylesheets/ava/grid.scss';

.shared-ui--modal-ui--modal {
  z-index: 1005;
  .ava-modal {
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -20%);
    background: white;
    z-index: 1001;
    padding: 1em;
    max-height: 90vh;

    &.small {
      min-width: 30vw;
    }

    &.medium {
      min-width: 60vw;
      overflow-y: auto;
    }

    &.large {
      min-width: 90vw;
      overflow-y: auto;
    }

    @media only screen and (max-width: $breakpoint-med) {
      &.small, &.medium, &.large {
        min-width: 100vw;
        max-height: 100vh;
      }
    }

    @media only screen and (max-height: $height-breakpoint-med) {
      &.small,
      &.medium,
      &.large {
        top: 10vh;
        transform: translate(-50%, 0%);
      }

      max-height: 80vh;
    }

    @media only screen and (max-height: $height-breakpoint-small) {
      &.small,
      &.medium,
      &.large {
        top: 5vh;
        transform: translate(-50%, 0%);
      }
      max-height: 90vh;
    }

    .title-container {
      margin-top: 0;
    }

    > div:first-child {
      display: flex;
      align-items: center;
      gap: 1em;

      > :first-child {
        flex: 1;
      }

      > :last-child {
        position: relative;
        top: -2px;
        margin-left: 15px;
        color: black;
        cursor: pointer;

        &::before {
          content: "\00D7";
          font-size: 2em;
        }
      }
    }

    > div:last-child {
      padding: 5px;
    }
  }

  .ava-modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    // background: rgba(150, 134, 163, 0.25);
    background: rgba(10, 10, 10, 0.45);
    z-index: 1000;
  }
}