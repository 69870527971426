
@import '@/styles/global-styles.scss';
.form-editor-textarea-container {
  .textarea-row-container {
    display: flex;
    align-items: center;
    gap: 1em;
    width: 100%;
    .form-template-editor-textarea {
      margin: 10px;
      width: inherit;
      background-color: white;
      border: none;
      border-bottom: 1px solid $ava-border-color;
      min-height: 100px !important;
    }
  }
}
