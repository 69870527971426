/* Use component-local class names and add them to HTML via #class_for(name) helper */

@import '../../../assets/stylesheets/ava/_variables.scss';
@import '../../../assets/stylesheets/ava/colors.scss';


.medications-ui-prescribe-it-messages-page-message-list-item.unread {
  * {
    font-weight: bold;
  }
  background-color: white;
}
.medications-ui-prescribe-it-messages-page-message-list-item {
  a:hover {
    background-color: transparent !important; 
  }
  background-color: $ava-background-color;
  display: flex;
  flex: 1;
  gap: 0.5em;
  padding: 0.5em;

  &:not(:last-child) {
    border-bottom: $ava-border;
  }

  .thread-list-item-button {
    display: flex;
    flex: 1;
    gap: 1em;
    flex-wrap: nowrap;
    padding: 0.5em;
    align-items: center;
    justify-content: space-between;
    border-radius: 0px;
  }

  .thread-title-right {
    text-align: right;
  }
  .thread-title-left {
    text-align: left;
  }
}